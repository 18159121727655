@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@import './App-theme.less';

/*
@font-face {
  font-family: 'IBMPlexSans';
  src: url('./IBMPlexSans-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'IBMPlexSans-Black';
  src: url('./IBMPlexSans-Black.ttf');
}
*/

@font-face {
  font-family: 'IBMPlexSans-Bold';
  src: url('./IBMPlexSans-Bold.ttf');
}
@font-face {
  font-family: 'IBMPlexSans-Thin';
  src: url('./IBMPlexSans-Thin.ttf');
}
@font-face {
  font-family: 'IBMPlexSans-ExtraLight';
  src: url('./IBMPlexSans-ExtraLight.ttf');
}
/*
@font-face {
  font-family: 'IBMPlexSans-ExtraBold';
  src: url('./IBMPlexSans-ExtraBold.ttf');
}*/

@font-face {
  font-family: 'IBMPlexSans-Medium';
  src: url('./IBMPlexSans-Medium.ttf');
}
@font-face {
  font-family: 'IBMPlexSans-Regular';
  src: url('./IBMPlexSans-Regular.ttf');
}
@font-face {
  font-family: 'IBMPlexSans-SemiBold';
  src: url('./IBMPlexSans-SemiBold.ttf');
}

html,
body {
  margin: 0;
  font-family: 'IBMPlexSans', 'IBMPlexSans-Bold', 'IBMPlexSans-Regular', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  //background: rgba(0, 0, 0, 0.8);
  //background: #161616;
  background: #000000;
  min-height: 100vh;
  .gaming {
    //cursor: url("./cursor.png"), pointer;
    cursor: pointer;

    .clickable:hover {
      transform: scale(1.2);
      transition: all 0.2s linear;
    }

    .clickable.adventure:hover {
      transform: scale(1.05);
    }

    .clickable.dungeon:hover {
      transform: scale(1.05);
    }

    .clickable:active {
      transform: scale(0.7);
      transition: all 0.4s linear;
    }

  }

  .ant-modal {
    padding-bottom: 0;
  }

  .ant-modal-close-x {
    display: none;
/*
    height: 36px;
    width: 36px;
    line-height: 40px;
    position: relative;
    top: 1rem;
    right: 1rem;
    */
  }

  .ant-modal-footer {
    //border: solid 2px #FFFFFF;
    //border-top: none;
  }

  .ant-modal-header, .ant-modal-title {
    background-color: #FFFFFF;
  }

  .ant-modal-body {
    //border: solid 2px #FFFFFF;
    //border-bottom: none;

    @media (max-width: 768px) {
      margin-top: 5rem;
      padding: 10px;
    }
  }

  .ant-input {
    color: #FFFFFF;
  }


  .ant-progress-text {
    //display: inline-block;
    //margin-left: 5px;
  }

  span.ant-input-affix-wrapper{
    background-color: transparent;
    border: none;

    .ant-input{
      background-color: transparent;
    }
  }
  .ant-modal-content {
    box-shadow: none;
  }

  .ant-modal-content {
    box-shadow: none;
  }

  .ant-notification-notice {
    //background: linear-gradient(180deg, #272321 30%, rgba(43, 38, 36, 0.3) 80%);
    // background: linear-gradient(11.68deg, #ff008a 0.64%, #0075ff 27.05%);
    border-radius: 35px 0 0 35px;
    //filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    //-webkit-background-clip: text;
    //-webkit-text-fill-color: #ff008a55;
    // background-clip: text;
    //text-fill-color: transparent;
    // opacity: 0.8;
    // backdrop-filter: blur(25px);
    background-color: #000000;
    color: #FFFFFF;
  }

  .ant-notification-notice-message {
    color: rgb(255, 255, 255);
    font-size: 1.5rem;
    font-weight: 900;
  }

  .ant-notification-notice-error {
    color: rgb(252, 60, 43);
    font-size: 1rem;
    font-weight: 600;
  }

  div.wkit-dialog__header {
    h2.wkit-dialog__title {
      color: #FFFFFF;
    }
  }

  div.wkit-select__container {
    div.wkit-select__title {
      display: none;
    }
  }
  div.wkit-select-item {
    color: #FFFFFF;
    &:hover {
      color: rgba(129, 128, 128, 0.29);
    }
  }

  div.wkit-dialog__content {
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.49);
    backdrop-filter: blur(45px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 30px;
    width: 35rem;
  }

 div.wkit-new-to-sui {
   display: none;
 }
}
